import tippy from 'tippy.js';
import { roundArrow } from 'tippy.js';
var navInit = function (user) {
    var currentPage = window.location.pathname.replace('/', '');
    var navLinks = {
        ncr: { name: 'Non-Conformance Report', icon: 'report' },
        issue: { name: 'Issues', icon: 'call_merge' },
        label: { name: 'Storage Label', icon: 'label' },
        // lookup: { name: 'Order Lookup',icon: 'find_in_page' },
        shipments: { name: 'Shipment Tracking', icon: 'local_shipping' },
        ua: { name: 'Usage Analytics', icon: 'multiline_chart' }
    };
    var navList = document.querySelector('ul.nav');
    var baseURL = "".concat(window.location.protocol, "//").concat(window.location.host, "/").concat(window.location.pathname.split('/')[0]);
    // Add avatar controls
    var el = document.createElement('li');
    el.classList.add('user-info');
    el.innerHTML = "\n    <a href=\"javascript:void(0)\">\n      <img src=\"".concat(user.gPic, "\" height=\"32\" width=\"32\" />\n      <span>").concat(user.name, "</span>\n    </a>\n  ");
    navList.appendChild(el);
    // End avatar
    for (var link in navLinks) {
        var n = navLinks[link];
        var el_1 = document.createElement('li');
        var samePage = (link === currentPage) ? true : false;
        var hyperlink = (samePage) ? 'javascript:void(0)' : baseURL + link;
        var tippy_1 = (samePage) ? '' : " data-tippy-content=\"".concat(n.name, "\"");
        if (samePage) {
            el_1.classList.add('disabled');
        }
        el_1.innerHTML = "\n      <a href=\"".concat(hyperlink, "\">\n        <i class=\"material-icons\"").concat(tippy_1, ">").concat(n.icon, "</i>\n        <span>").concat(n.name, "</span>\n      </a>");
        navList.appendChild(el_1);
    }
    //* Only load Tippy on Nav if window is larger than 780px - desktop *//
    if (window.innerWidth > 780) {
        var tooltip = tippy('[data-tippy-content]', {
            placement: 'right',
            arrow: false
        });
    }
    var navIcon = document.querySelector('.nav-icon');
    var menu = document.querySelector('.menu');
    navIcon.addEventListener('click', function () {
        navIcon.classList.toggle('open');
        menu.classList.toggle('open');
    });
    var template = tippy(document.querySelector('.user-info > a > img'), {
        theme: 'user',
        content: "<div><a href=\"javascript:void(0)\" onclick=\"firebase.auth().signOut()\">Log Out</a></div>",
        interactive: true,
        allowHTML: true,
        placement: 'bottom',
        trigger: 'click',
        triggerTarget: document.querySelector('.user-info'),
        arrow: roundArrow,
        appendTo: document.body,
        onShow: function (ins) {
            console.log('clicked');
        }
    });
};
export default navInit;
