// @ts-nocheck
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as firebaseui from 'firebaseui';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import Nav from '../../components/nav';
firebase.initializeApp({
    apiKey: "AIzaSyCKmLcmRYCPNXUAtVycYndMjY15nqK3P5U",
    authDomain: "jidoka.ca",
    databaseURL: "https://jidoka-voltera.firebaseio.com",
    projectId: "jidoka-voltera",
    storageBucket: "jidoka-voltera.appspot.com",
    messagingSenderId: "341413025518",
    appId: "1:341413025518:web:f713b7fd0313819a"
});
export var auth = firebase.auth();
var User = /** @class */ (function () {
    function User() {
        var _this = this;
        auth.onAuthStateChanged(function (user) { return __awaiter(_this, void 0, void 0, function () {
            var pre_1, template;
            var _this = this;
            return __generator(this, function (_a) {
                if (user) {
                    this.name = user.displayName;
                    this.email = user.email;
                    this.uid = user.uid;
                    this.gPic = user.photoURL;
                    if (window.location.pathname === '/') {
                        document.getElementById('firebaseui-auth-container').style.display = 'none';
                        pre_1 = document.getElementById('pre-catch');
                        template = "\n            <div id=\"pre-button\">\n              <img src=\"".concat(user.photoURL, "\" height=\"40\" width=\"40\"/>\n              <div>\n                <span>").concat(user.displayName, "</span>\n                <span>").concat(user.email, "</span>\n              </div>\n              <a href=\"javascript:void(0)\"><span class=\"material-icons\">arrow_forward</span></a>\n            </div>\n            <span><a href=\"javascript:void(0)\" id=\"pre-notme\">Not You?</a></span>");
                        pre_1.innerHTML = template;
                        // Click Event Listener's
                        document.getElementById('pre-button').addEventListener('click', function (e) {
                            e.preventDefault();
                            window.location.href = window.location.protocol + '//' + window.location.host + '/ncr';
                        });
                        document.getElementById('pre-notme').addEventListener('click', function (e) {
                            e.preventDefault();
                            pre_1.innerHTML = '';
                            document.getElementById('firebaseui-auth-container').style.display = 'block';
                            _this.signOut();
                        });
                    }
                    else {
                        Nav({
                            email: this.email,
                            name: this.name,
                            gPic: this.gPic
                        });
                    }
                }
                else {
                    if (window.location.pathname !== '/') {
                        window.location.href = window.location.origin;
                    }
                    this.signIn();
                }
                return [2 /*return*/];
            });
        }); });
    }
    User.prototype.signIn = function () {
        var ui = new firebaseui.auth.AuthUI(auth);
        ui.start('#firebaseui-auth-container', {
            callbacks: {
                signInSuccessWithAuthResult: function (authResult, redirectUrl) {
                    var emailDomain = authResult.user.email.split('@')[1];
                    if (emailDomain !== 'voltera.io') {
                        firebase.auth().signOut().then(function () {
                            window.location.href = "errors/noauth.html";
                        });
                        return false;
                    }
                    else {
                        localStorage.setItem('user', JSON.stringify({
                            name: authResult.user.displayName,
                            email: authResult.user.email,
                            photo: authResult.user.photoURL,
                            uid: authResult.user.uid
                        }));
                    }
                    return true;
                },
                // uiShown: function() {}
            },
            signInFlow: 'popup',
            signInOptions: [
                {
                    provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                    customParameters: {
                        prompt: 'select_account'
                    }
                },
                // firebase.auth.EmailAuthProvider.PROVIDER_ID
            ],
            // set this to pathname
            signInSuccessUrl: window.location.protocol + '//' + window.location.host + '/ncr'
        });
    };
    User.prototype.signOut = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, auth.signOut()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return User;
}());
export default User;
